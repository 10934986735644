.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.rule {
  background: linear-gradient(to right, transparent 0%, #cccccc 10%, #ffffff 50%, #cccccc 90%, transparent 100%);
  height: 1px; /* Adjust the height as needed */
  width: 100%; /* Full width */
  margin: 20px 0; /* Adjust the margin as needed */
}

#qsLoginBtn {
    background: linear-gradient(to right, #007bff, #0dcaf0);
    background-color: #0dcaf0!important;
    border: none;
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
    font-weight: bold;
    font-family: "Poppins", sans-serif !important;
    /* cursor: pointer; */
    font-size: 20px;
    margin-bottom: 0px;
}
#qsLStartBtn {
    background: linear-gradient(to right, #198754, #198754);
    background-color: #198754!important;
    border: none;
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
    font-weight: bold;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 0px;
}
#qsLStartBtn:hover {
  background: linear-gradient(to right, lightgreen, #198754); /* Adjust colors as needed */
}
th {
  position: relative;
}
.catgo {
  float: right;
  position: absolute;
  top: 4px;
  right: 6px;
  font-weight: 400;
  text-transform: uppercase;
  color: #bebebe;
  font-size: 17px;
}
.btncontainer {
  display: flex;
  /* padding-bottom: 40px; */
}
#qsLoginBtn:hover {
  background: linear-gradient(to right, #0056b3, #007bff); /* Adjust colors as needed */
  
}
.btncontainer .btn-block {
  padding: 0px;
  margin: 0px;
  height: 48px;
  border-radius: 10px;
  margin: 0px 8px 20px 8px;
}
#qsCancelBtn {
  background: linear-gradient(to right, #6c757d, #495057); /* Adjust colors as needed */
  border: none;
  font-weight: bold;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    font-size: 20px;
  color: #ffffff; /* Text color */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Optional: Add a subtle text shadow */
  transition: background 0.3s ease-in-out; /* Optional: Add a smooth transition effect */
}
a {
  color: #0dcaf0 !important;
}
.notfound {
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}
#qsCancelBtn:hover {
  background: linear-gradient(to right, #495057, #6c757d); /* Adjust colors as needed */
}
.logo-tcg {
  padding-top: 4px;
  padding-bottom: 14px;
}
.labelil {font-size: 11px;}
.logo-tcg2 {
  padding-top: 4px;
    text-align: center;
    width: 140px;
    float: right;
    padding-bottom: 0px;
    font-style: italic;
    margin-bottom: 0px !important;
    /* margin-rgih-valut: 18px; */
}
.caprio {

}


.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
h1 {
  font-size: 22px;
}
.btn-game {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
}
.row-container {
  width: 100%;
  flex-direction: row;
  margin-top: 0px;
  display: flex;
}
.smllrow {
  flex: .4;
}
.bigrow {
  flex: 1;
}

.app-logo {
  justify-content: center;
  width: 50px;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.active-game {
  display: flex;
}
.game-table {
  width: 100%;
}
.image-area {
  display: flex;
  padding-top: 12px;
  font-size: 14px;
}
.btn-primary.disabled, .btn-primary:disabled {
  cursor: not-allowed;
  /* display: none; */
}
.label-spacing {
  padding-right: 15px;
}
.red-btn {
  background-color: red;
  border-color: darkred;
}
.green-btn {
  background-color: green;
  border-color: darkgreen;
  justify-content: center;
}
.colly {
  flex-direction: column;
  display: flex;
  flex: .4;
  text-align: center;
}
.main-img {
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
.image-holder {
  display: flex;
}
.collypad {
  flex-direction: column;
  display: flex;
  text-align: center;
  flex: 1;
}
.thumb-holder {
  display: flex;
  margin-bottom: 32px;
  margin-top: 8px;
}
.thumb-img {
  height: 60px;
  width: 60px;
  margin: 0 auto;
}
.thumb-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 11px;
}
.descrip {
  text-align: center;
  font-size: 23px;
  padding-top: 12px;
}
.align-cent {
  text-align: center;
}
.game-id {
  justify-content: space-between;
  color: blue;
  padding: 20px 10px;
}
.caption {
  padding-top: 8px;
}
.status {
  margin-top: 20px;
  font-size: 18px;
  opacity: .5;
}
.titles {
  text-align: center;
  font-size: 14px;
  padding-top: 4px;
  font-weight: bold;
}

.knobber {
  width: 240px;
  left: -15%;
  position: absolute;
  top: -13%;
}

@keyframes spin {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.mt-5, .my-5 {
  margin-top: 2rem!important;
}
.whitec {
  color: white !important
}
.logoholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dlogo {
  margin-top: 25%
}
.ui-inputgroup select {
  word-wrap: normal;
  border-radius: 6px;
  height: 42px;
  padding-left: 7px;
  padding-right: 7px;
}
.ui-inputgroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}
.ui-inputgroup input {
  border-radius: 6px;
}
.ui-inputgroup label {
  font-size: 24px;
}

.form1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.form1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form1 > div {
  width: 100%; /* Items will take 100% width by default */
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
  font-family: "Poppins", sans-serif;
}

.form1 > div.half-size {
  width: 49%; /* Items with this class will take 50% width */
}
.rdp-header {
  color: black;
}
.form1 > div.half-size:nth-child(3), .form1 > div.half-size:nth-child(5), .form1 > div.half-size:nth-child(7), .form1 > div.half-size:nth-child(9) {
  margin-right: 2%;
}


body {
	/* background : radial-gradient(circle at center, grey 0, #181818 30%, black 100%) */
}
.form-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .form-head h3 {
  font-family: "Poppins", sans-serif !important;
  font-family: "Protest Riot", sans-serif;
  color: gray;
  font-size: 26px;
} */
.ui-inputgroup label {
  font-size: 18px;
  padding-bottom: 0px;
  margin-bottom: 3px;
  font-weight: 500;
}
.main-content {
  max-width: 620px;
}
.dcontainer {
	width : 300px;
	height: 400px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


.dcover {
  display: inline-block;
  height: 400px;
	width: 300px;
	margin: 15px;
	border-radius: 8px;
}

@media screen and (max-width: 700px) {
  .image-from-label {
    max-width: 327px !important;
}
	.dcontainer {
		width : 100%;
		position: relative;
		transform: translate(0%, 0%);
		top: 0;
		left: 0;
	}
	.dcover {
		display: block;
		height: 300px;
		width: 300px;
		margin: 15px auto;
	}
}

.atvImg {
	border-radius: 8px;
	transform-style: preserve-3d;
	-webkit-tap-highlight-color: rgba(#000,0);
}

.atvImg img {
	border-radius: 8px;
	box-shadow: 0 2px 8px rgb(0, 0, 0);
}

.atvImg-container {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	transition: all 0.2s ease-out;
}

.atvImg-container.over .atvImg-shadow {
	box-shadow: 0 45px 100px rgba(0, 0, 0, 1), 0 16px 40px rgba(0, 0, 0, 1);
}

.atvImg-layers {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 8px;
	overflow: hidden;
	transform-style: preserve-3d;
}

.atvImg-rendered-layer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0%; 
	left: 0%;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	background-size: cover;
	transition: all 0.1s ease-out;
	overflow: hidden;
	border-radius: 8px;
}

.atvImg-shadow {
	position: absolute;
	top: 5%; 
	left: 5%;
	width: 90%;
	height: 90%;
	transition: all 0.2s ease-out;
	box-shadow: 0 8px 30px rgba(14,21,47,0.6);
}

.atvImg-shine {
	position: absolute;
	top: 0; 
	left: 0; 
	right: 0; 
	bottom: 0;
	border-radius: 8px;
	background: linear-gradient(135deg, rgba(255,255,255,.25) 0%,rgba(255,255,255,0) 60%);
}
.ui-inputgroup input {
  border-radius: 6px;
  height: 42px;
  padding-left: 7px;
}

@media (min-width: 1200px){
.container {
    max-width: 660px;
}}

@media (min-width: 992px){
.container {
    max-width: 660px;
}}

@media (max-width: 768px) {
  .btncontainer {
    display: flex;
    flex-direction: column;
  }
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
  .form1 > div.half-size {
    width: 100%;
    margin-right: 0px !important;
}
.form1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 97%;
    margin: 0 auto;
}

}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
}
.linksp {
  font-size: 18px;
  padding-top: 8px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  text-decoration: underline;
  color: #0dcaf0!important;
  cursor: pointer;
}
.headyc {
  display: flex !important;
  flex-direction: column;
  text-align: center !important;
}
.ReactModal__Overlay {
  background: none;
  background-color: #000 !important;
  border-color: #000 !important;
}
.ReactModal__Content table {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
}
.ReactModal__Content {
  background-color: #000 !important;
  color: white !important;
  border: 0px !important;
  inset:0px !important;
  padding: none !important;
}
.custom-table {
  width: 100%;
  color: white;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  border: 1px solid #444;
  padding: 8px;
}

.even-row {
  background-color: black;
}

.odd-row {
  background-color: #1a1a1a; /* Light black color */
}

/* Additional styles for approve and delete buttons */
.custom-table div {
  padding: 5px;
  cursor: pointer;
  margin: 2px;
  background-color: #3498db; /* Example color, you can change this */
  color: white;
  text-align: center;
}

.custom-table div:hover {
  background-color: #2980b9; /* Example color, you can change this */
}

.buttono {
  font-weight: bold;
  border-radius: 5px;
  background-color: #198754!important;
  width: 49%;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttono.del {
  font-weight: bold;
  border-radius: 5px;
  background-color: #dc3545!important
}
.modal-headers {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333; /* Adjust the background color as needed */
}

.modal-headers h2 {
  color: white;
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}
.button-container {
  display: flex;
  place-content: space-between;
}
.ui-inputgroup input {
  width: 100%;
}
.grade-holder {
  display: flex;
  /* justify-content: center; */
 font-size: 18px;
  align-items: center;
}
.grade-holder h2 {
 font-size: 26px;
 margin-left:  8px;
}
.image-from-label {
  max-width: 590px;
}