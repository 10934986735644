@import url('https://fonts.googleapis.com/css2?family=Mansalva&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom right, #333333, #000000);
  color: white;
  /* font-family: 'Mansalva', cursive; */
}
.thinner-progress-bar {
  height: 4px; /* Adjust the height as needed */
}
.container .btn-block, .grid-holder .btn {
  text-shadow: 3px 2px #000;
  font-size: 32px;
  margin-bottom: 12px;
  border: 0px;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 12%, rgba(30,30,30,1) 30%, rgba(61,61,61,1) 50%, rgba(30,30,30,1) 70%, rgba(0,0,0,1) 88%);
}
.grid-holder .btn {
  font-size: 22px;
  margin-left: 10px;
}


.loading-screen1 h1 {
  font-size: 40px;
  color: #ffcc00;
  /* padding-top: 50px; */
  text-align: center;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
}

.container .sub_div {
  position: absolute;
  bottom: 2%;
  width: 90%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.peclass {
  margin-top: -8px;
  margin-bottom: 16px;
  color: yellow;
  font-size: 15px;
  text-align: right;
  padding-right: 22px;
}
.flabel {
  font-size: 18px;
  display: inline-block;
  margin-bottom: 0.5rem;
  display: flex;
  margin: 0px 11px 8px 11px;
  align-items: center;
}
/*  animation */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.loading-screen {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.loading-screen {
  font-size: 46px;
  width: 75%;
    margin: 0 auto;
}
@keyframes loadingAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes progressBarAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.container button:hover {
  color: #111;
  background: none;
  background-color: white;
  text-shadow: none;
}
.container button:hover h1 {
  color: #111 !important;
  text-shadow: none;
}
.logoutbtn {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
.doublemarg {
  margin-top: 20px;
}
.game-info {
  font-size: 18px;
}
.yellow {
  color: #ffcc00;
}
.modal-content {
  background-color: #000;
  /* border: 1px solid #bebebe; */
  /* height: 97vh; */
  overflow-y: auto;
  border: 0.5px solid #666;
  height: auto;
}
.modal-header {
  border-style: solid;
  border-width: 1px; /* Adjust the border width as needed */
  border-image: linear-gradient(to right, transparent 0%, #cccccc 10%, #ffffff 50%, #cccccc 90%, transparent 100%);
  border-image-slice: 1; /* Ensure the entire border is covered with the gradient */
}
.pokemon-header {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), url(./assets/poke-bgd.png) !important;
  background-size: cover; /* or background-size: 100%; */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}
.sports-header {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), url(./assets/sports-bgd.png) !important;
  background-size: cover; /* or background-size: 100%; */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}
.yugio-header {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), url(./assets/yugio-bgd.png) !important;
  background-size: cover; /* or background-size: 100%; */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}
.baseball-header  {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), url(./assets/baseball-bgd.png) !important;
  background-size: cover; /* or background-size: 100%; */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}
.lorcana-header {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), url(./assets/lorcana-bgd.png) !important;
  background-size: cover; /* or background-size: 100%; */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}
.mtg-header {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), url(./assets/mtg-bgd.png) !important;
  background-size: cover; /* or background-size: 100%; */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}
.modal-footer {
  border-style: solid;
  border-width: 1px; /* Adjust the border width as needed */
  border-image: linear-gradient(to right, transparent 0%, #cccccc 10%, #ffffff 50%, #cccccc 90%, transparent 100%);
  border-image-slice: 1; /* Ensure the entire border is covered with the gradient */
}
.list-group-item {
  background-color: transparent;
}
.modal-title {
  font-size: 37px;
    font-family: "Protest Riot", sans-serif;
    /* color: #0dcaf0!important; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ebebeb;
    padding-left: 20px;
}
.smallhead {
  font-size: 22px;
    /* float: right; */
    color: #bebebe;
}
.modal-label {
  font-size: 14px;
  font-weight: bold;
}
.modal-value {
  font-size: 22px;
  /* font-weight: bold; */
}
.modal-value2 {
  font-size: 18px;
  /* font-weight: bold; */
}

.p-cont {
  display: flex;
  flex-direction: column;
}
.modal-body {
  font-family: "Poppins", sans-serif;
}
.game-grid {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
}
.game-itme {
  display: flex;
}
.grid-holder {
  display: flex;
  /* flex-direction: column; */
}
.headerbody {
  
}
.game-label {
  font-weight: bold;
  padding-right: 4px;
}
.game-value {
  color: #ffcc00;
}
.list-group-item {
  background-color: black;
  border-radius: 10px !important;
  margin-bottom: 7px;

}
.name-label {
  position: relative;
  width: 100%;
  flex: 1;
  text-align: left;
}
.name-label .name .name-banner {
  border-radius: 25px;
  background-color: white;
  padding: 6px;
  font-weight: bold;
  font-size: 26px;
  padding-left: 14px;
}
.current-card-view {
  margin-bottom: 55px;
}
.img-card-hold {
  border: 7px solid #fff;
  transform: rotate(10deg);
  width: 114px;
  height: 114px;
  position: absolute;
  top: -4px;
  right: 1px;
  border-radius: 8px;
  z-index: 9;
  background-color: purple;
}
.body-cont {
  display: flex;
}
.name-label .name span {
  font-size: 16px;
  color: white;
  padding-left: 8px;
}
.name-label .name {
  display: flex;
  flex-direction: column;
  color: black;
}
.white-container {
  background-color: #fff;
  border-radius: 18px;
  padding: 10px;
  position: relative;
}
.white-container h1 {
  color: black;
  font-size: 24px;
  padding-top: 8px;
  
}
.hrmods {
  margin-top: -26px;
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create 4 columns with equal width */
  gap: 14px; /* Add some space between cards */
}

/* CSS for .game-card */
.game-card {
  border: 1px solid #ccc; /* Add a border to each card */
  /* padding: 10px; Add some padding inside each card */
  text-align: center; /* Center the content */
  position: relative;
  cursor: pointer;
}
.overlay-bean {
  position: absolute;
  width: 100%;
  top: 0px;
  height: 100%;
  background: #000;
  opacity: .5;
  font-size: 60px;
  line-height: 48px;
}
/* Responsive design: adjust the card size for smaller screens */
@media (max-width: 768px) {
  .game-grid {
    grid-template-columns: repeat(4, 1fr); /* Change to 4 columns on smaller screens */
  }
  .modal-title {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    margin-top: 0px;
    width: 100%;
    font-size: 28px;
  }
  .pokemon-header {
    padding-left: 8px;
    padding-right: 0px;
  }
  .sports-header, .mtg-header, .lorcana-header, .baseball-header, .yugio-header  {
    padding-left: 8px;
    padding-right: 0px;
  }
  .smallhead {
    font-size: 20px !important;
}
.container h3{
  font-size: 30px;
}
.modal-value {
  font-size: 20px;
  /* font-weight: bold; */
}
.container h3{
  font-size: 28px !important;
  text-align: center;
}

}

.refreshico {
  color: royalblue;
  position: absolute;
  top: 23px;
  right: 19px;
  cursor: pointer;
}

.hero2 {
  margin-top: 0px !important;
}
.card-image {
  width: 100%;
}
.game-grid2 {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    flex-direction: column !important;
}
.mutedgrey {
  color: #666 !important;
    padding-top: 0px !important;
    padding-bottom: 2px;
    margin-top: -38px;
}

@keyframes rotate90 {
  0% {
    transform: rotate(0deg); /* Start with no rotation */
  }
  50% {
    transform: rotate(90deg); /* Rotate 90 degrees clockwise */
  }
  100% {
    transform: rotate(0deg); /* Rotate back to the starting position */
  }
}

.refresh-icon {
  animation: rotate90 1s infinite; /* Apply the animation for 2 seconds infinitely */
}
h5 {
  margin-top: 28px;
  margin-bottom: 0px;

}
.placeholder {
  width: 25%;
  height: 75px;
}
.btn {
  outline: 0 !important;
}
.container button:hover, .container button:active, .container button:focus {
  outline: 0 !important;
}
.container button {
  outline: 0 !important;
}

button:active {outline:0;}
button:focus {outline:0;}

.custom-modal {
  max-width: 400px; /* Set the maximum width for the modal */
  margin: auto; /* Center the modal horizontally */
  border-radius: 8px; /* Add rounded corners to the modal */
}

.custom-modal .modal-dialog {
  margin: 0; /* Remove margins to make it more like a dialog */
}

.custom-modal .modal-content {
  height:auto;
  border: none; /* Remove borders for a clean appearance */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a shadow for depth */
}






.container h3{
  font-family: "Protest Riot", sans-serif;
  /* font-family: "Poppins", sans-serif */
  /* font-family: 'Mansalva', cursive; */
  font-size: 30px;
  /* color: #0dcaf0!important; */
  position: relative;
  display: inline-block;
font-weight: bold;
  color: #fff !important;
  /* color: #000 !important; */
  /* text-shadow: -1px -1px 0 #fff, 0px 0px 0 #000, 0px 2px 0 #fff, 4px 1px 0 #fff; */
  margin-right: 8px;
}
